import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
    data: {
      errorCode: 500,
      errorText:
        'Omlouváme se, na serveru došlo k nějaké chybě. Zkuste za chvíli obnovit stránku.',
    },
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
    data: {
      errorCode: 404,
      errorText:
        'Je nám líto, ale požadovaná stránka nebyla nalezena. Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.',
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
