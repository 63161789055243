import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonJsonOptions } from '../models';

@Injectable()
export class CommonHttpService {
  constructor(private http: HttpClient) {}

  private getResponseObject<ServerType, ClientType>(
    response: ServerType,
    mapFce?: (rawData: ServerType) => ClientType
  ): ClientType {
    return mapFce != null
      ? mapFce(response as ServerType)
      : ((response as unknown) as ClientType);
  }

  private errorProject(error: HttpErrorResponse): Observable<any> {
    let er: any = null;
    if (error.error) {
      er = error.error;
    } else {
      if (error.message) {
        er = error.message;
      }
    }
    if (er) {
      console.log(er);
    }

    return of(false);
  }

  public get<ServerType, ClientType>(
    url: string,
    mapFce?: (rawData: ServerType) => ClientType,
    options?: CommonJsonOptions
  ): Observable<ClientType> {
    return this.http.get(url, options ? options : undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error) => this.errorProject(error))
    );
  }

  public post<ServerType, ClientType>(
    url: string,
    body: any,
    mapFce?: (rawData: ServerType) => ClientType,
    options?: CommonJsonOptions
  ): Observable<ClientType> {
    return this.http.post(url, body, options ? options : undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error))
    );
  }

  public put<ServerType, ClientType>(
    url: string,
    body: any,
    mapFce?: (rawData: ServerType) => ClientType,
    options?: CommonJsonOptions
  ): Observable<ClientType> {
    return this.http.put(url, body, options ? options : undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error))
    );
  }

  public delete<ServerType, ClientType>(
    url: string,
    mapFce?: (rawData: ServerType) => ClientType,
    options?: CommonJsonOptions
  ): Observable<ClientType> {
    return this.http.delete(url, options ? options : undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error))
    );
  }

  public patch<ServerType, ClientType>(
    url: string,
    body: any,
    mapFce?: (rawData: ServerType) => ClientType,
    options?: CommonJsonOptions
  ): Observable<ClientType> {
    return this.http.patch(url, body, options ? options : undefined).pipe(
      map((res: any) => {
        return this.getResponseObject<ServerType, ClientType>(res, mapFce);
      }),
      catchError((error: HttpErrorResponse) => this.errorProject(error))
    );
  }
}
