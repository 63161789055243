import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CmsColModel, CmsCrawlerResultModel, CmsRowModel, CmsSectionModel } from '@shared/models';

@Injectable()
export class CrawlerHelperService {
  constructor() { }

  public updateMetaForCrawlers(sectionList: CmsSectionModel[], sectionFromQuery: string, pageMeta: Meta) {
    for (const section of sectionList) {
      const foundData = this.mineDataFromRows(section.rows, sectionFromQuery);
      this.updateMetaIfNeeded(pageMeta, foundData);
    }
  }

  private updateMetaIfNeeded(pageMeta: Meta, foundData: CmsCrawlerResultModel) {
    if (foundData) {
      pageMeta.updateTag({ name: 'og:title', content: foundData.title?.text });
      pageMeta.updateTag({ name: 'og:description', content: foundData.description?.text });
    }
  }

  private mineDataFromRows(rows: CmsRowModel[], sectionFromQuery: string): CmsCrawlerResultModel {
    for (const row of rows) {
      for (const col of row.cols) {
        if (col.rows != null) {
          const foundData = this.mineDataFromRows(col.rows, sectionFromQuery);
          if (foundData) {
            return foundData;
          }
        } else {
          return this.getMetadataFromContent(row.cols, sectionFromQuery);
        }
      }
    }
  }

  private getMetadataFromContent(columns: CmsColModel[], sectionFromQuery: string): CmsCrawlerResultModel {
    for (const element of columns) {
      if (
        element.content?.contentType === 'crawler-data' &&
        element.content?.crawlerData.id_ref === sectionFromQuery) {
        return {
          description: {
            text: element.content.crawlerData.paragraph?.text,
            text_en: element.content.crawlerData.paragraph?.text_en
          },
          title: {
            text: element.content.crawlerData.header?.text,
            text_en: element.content.crawlerData.header?.text_en
          }
        };
      }
    }
  }
}
