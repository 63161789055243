import { Injectable } from '@angular/core';
import { CmsMapperService } from './cms-mapper.service';
import { Observable, combineLatest, of } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import { CommonAppConfig, CommonHttpService } from '@core/services';
import { WidgetsService } from './widgets.service';
import {
  CmsPageModel,
  CmsPageHttpModel,
  CmsHeaderHttpModel,
} from '@shared/models/cms';
import { CmsHeaderModel } from '@shared/models/cms/cms-header.model';

@Injectable()
export class CmsService {
  constructor(
    private cmsMapper: CmsMapperService,
    private config: CommonAppConfig,
    private http: CommonHttpService,
    private widgetsService: WidgetsService
  ) {}

  getCmsHeader(): Observable<CmsHeaderModel> {
    const url = this.config.get().cmsUrl + '/header';

    return this.http
      .get(url, (response: CmsHeaderHttpModel) => {
        return this.cmsMapper.mapCmsHeader(response);
      })
      .pipe(shareReplay(1));
  }

  getCmsDataForPage(type: string): Observable<CmsPageModel> {
    const url = this.config.get().cmsUrl + '/' + type;

    const cmsForPage$ = this.http.get(url, (response: CmsPageHttpModel) => {
      return this.cmsMapper.mapCmsDataForPage(response);
    });

    return cmsForPage$.pipe(
      concatMap((cmsData) => {
        if (!cmsData) {
          return combineLatest([of(null), of(null)]);
        }
        // http requests
        const widgets$ = this.widgetsService.getWidgetsForPage(
          cmsData.widgetIds
        );

        return combineLatest([of(cmsData), widgets$]);
      }),
      map(([cmsData, widgets]) => {
        if (cmsData == null || widgets == null) {
          return null;
        }
        const result = this.cmsMapper.mapWidgetsDataToCmsData(cmsData, widgets);
        return result;
      }),
      shareReplay(1)
    );
  }
}
