import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';

import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvDataModel } from '../models';

@Injectable()
export class CommonAppConfig {
  private config: EnvDataModel = null;

  constructor(
    private http: HttpClient,
    private platformLocation: PlatformLocation
  ) {}

  get(): EnvDataModel {
    return this.config;
  }

  load(): Promise<{}> {
    return new Promise((resolve, reject) => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(' electron/') > -1) {
        const platformLocation: any = this.platformLocation;
        const filePath =
          platformLocation.location.href.replace(/index\.html.*/, '') +
          'config.json?d=' +
          new Date().toISOString();

        fetch(filePath)
          .then((x) => x.json())
          .then((x) => {
            this.config = x;
            resolve(true);
          })
          .catch((x) => {
            console.error('ERROR: config could not be loaded');
            resolve(x);
            return throwError(
              /*error.json().error ||*/ 'ERROR: config could not be loaded'
            );
          });
      } else {
        this.http
          .get(
            window.location.origin +
              '/config.json?d=' +
              new Date().toISOString()
          )
          // TODO: upravit kvuli ElectronApp
          .pipe(
            map((res) => res),
            catchError((error: any) => {
              console.error('ERROR: config could not be loaded');
              resolve(error);
              return throwError(
                /*error.json().error ||*/ 'ERROR: config could not be loaded'
              );
            })
          )
          .subscribe((responseData: EnvDataModel) => {
            this.config = responseData;
            resolve(true);
          });
      }
    });
  }
}
