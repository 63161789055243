import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonAppConfig } from '@core/services';

@Component({
  selector: 'pr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portal';
  isConsentWithAnalyticsCookies = false;
  googleAnalyticsID: string;
  googleAnalyticsCookielessID: string;
  cookieconsent: typeof window.CookieConsent;

  constructor(public router: Router, config: CommonAppConfig) {
    this.googleAnalyticsID = config.get().googleAnalyticsID;
    this.googleAnalyticsCookielessID = config.get().googleAnalyticsCookielessID;
    this.appendGaTrackingCodeAndDisable(this.googleAnalyticsID);
    this.appendGaTrackingCodeCookielessAndDisable(
      this.googleAnalyticsCookielessID
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.gtag('config', this.googleAnalyticsID, {
          page_path: event.urlAfterRedirects,
          SameSite: 'None',
        });
        window.gtag('config', this.googleAnalyticsCookielessID, {
          page_path: event.urlAfterRedirects,
          anonymize_ip: true,
          SameSite: 'None',
        });
      }
    });
    this.appendCookieConsent();
  }

  private appendGaTrackingCodeAndDisable(googleAnalyticsID) {
    try {
      // Note: gtag scripts are already injected in the SSG build
      if (!window.ga) {
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute(
          'src',
          `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`
        );
        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());`;
        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
      }
      // disable ga
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
  private appendGaTrackingCodeCookielessAndDisable(
    googleAnalyticsCookielessID
  ) {
    try {
      // Note: gtag scripts are already injected in the SSG build
      if (!window.ga) {
        const gaScript = document.createElement('script');
        gaScript.setAttribute('async', 'true');
        gaScript.setAttribute(
          'src',
          `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsCookielessID}`
        );
        const gaScript2 = document.createElement('script');
        gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());`;
        document.documentElement.firstChild.appendChild(gaScript);
        document.documentElement.firstChild.appendChild(gaScript2);
      }
      // disable ga
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
  private appendCookieConsent() {
    try {
      // ./assets/js/cookieconsent.js has to be load before
      // also disable pre-rendering of the cookie panel
      if (
        !window.initCookieConsent ||
        /HeadlessChrome/.test(window.navigator.userAgent)
      ) {
        return;
      }
      window.CookieConsent = this.cookieconsent = window.initCookieConsent();
      this.cookieconsent.run({
        current_lang: 'cs',
        autoclear_cookies: true, // default: false
        cookie_name: 'pragozorcz-cookieconsent', // default: 'cc_cookie'
        page_scripts: true,
        gui_options: {
          consent_modal: {
            layout: 'bar', // box,cloud,bar
            position: 'bottom center', // bottom,middle,top + left,right,center
            transition: 'slide', // zoom,slide
          },
          settings_modal: {
            layout: 'box', // box,bar
            transition: 'slide', // zoom,slide
          },
        },
        onAccept: (cookie: any) => {
          for (const level of cookie.level) {
            // analytics category
            if (level === 'analytics' && this.googleAnalyticsID) {
              this.isConsentWithAnalyticsCookies = true;
              // ga enable
              window.gtag('consent', 'update', {
                analytics_storage: 'granted',
              });
            }
          }
        },
        onChange: (cookie: any, changedPreferences: any) => {
          // If analytics category's status was changed
          if (changedPreferences.indexOf('analytics') > -1) {
            // If analytics category is disabled
            if (!this.cookieconsent.allowedCategory('analytics')) {
              // Disable ga
              this.isConsentWithAnalyticsCookies = false;
              window.gtag('consent', 'update', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
              });
            } else {
              // Enable ga
              this.isConsentWithAnalyticsCookies = true;
              window.gtag('consent', 'update', {
                analytics_storage: 'granted',
              });
            }
          }
        },
        languages: {
          cs: {
            consent_modal: {
              title: 'Používáme cookies',
              description:
                'Díky využití souborů cookies zjišťujeme, co je pro uživatele zajímavé. Analýza návštěvnosti nám pomáhá web neustále zlepšovat. Nepoužíváme cookies k marketingovým účelům, ani je nepředáváme nikomu dalšímu. <br><br> Dovolíte nám je takto používat?',
              primary_btn: {
                text: 'Povolit vše',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Nastavení cookies',
                role: 'c-settings', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Přizpůsobit nastavení cookies',
              save_settings_btn: 'Uložit nastavení',
              accept_all_btn: 'Povolit vše',
              reject_all_btn: 'Povolit nezbytné',
              close_btn_label: 'Zavřít',
              blocks: [
                {
                  title: 'Nezbytně nutné cookies',
                  description:
                    'Tyto cookies pomáhají, aby webová stránka byla použitelná a fungovala správně. Ve výchozím nastavení jsou povoleny a nelze je zakázat.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                  },
                },
                {
                  title: 'Statistika',
                  description:
                    'Díky těmto cookies víme, kam u nás chodíte nejraději a máme statistiky o celkové návštěvnosti našich stránek.',
                  toggle: {
                    value: 'analytics', // there are no default categories => you specify them
                    enabled: false,
                    readonly: false,
                  },
                },
              ],
            },
          },
        },
      });
    } catch (ex) {
      console.error('Error appending cookie consent');
      console.error(ex);
    }
  }
}
