import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private router: Router) {}

  handleError(error) {
    console.error('Error:', error);
    this.router.navigate(['/error']);
  }
}
