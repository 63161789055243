import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { CmsLocalizedTextModel } from '@shared/models';

@Pipe({
  name: 'localizedText',
})
export class LocalizedTextPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(
    localizedText: CmsLocalizedTextModel | object,
    defaultKey = 'text'
  ): string {
    if (localizedText == null || localizedText[defaultKey] == null) {
      return '';
    }
    const keys = Object.keys(localizedText).map((x) => x.split('_')[1]);
    const matchedKey = keys.find((key) => this.locale.indexOf('key') > -1);

    return matchedKey != null
      ? localizedText[defaultKey + '_' + matchedKey]
      : localizedText[defaultKey];
  }
}
