import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  WidgetHttpModel,
  BaseMetadataHttpModel,
  WidgetModel,
} from '@shared/models/widgets';
import { CommonAppConfig, CommonHttpService } from '@core/services';
import { poll } from '@shared/utils';
import { WidgetsMapperService } from './widgets-mapper.service';

@Injectable()
export class WidgetsService {
  constructor(
    private widgetsMapper: WidgetsMapperService,
    private config: CommonAppConfig,
    private http: CommonHttpService
  ) {}

  getWidgetsForPage(ids: string[]): Observable<WidgetModel<any>[]> {
    if (ids.length === 0) {
      return of([]);
    }
    const params = ids
      .map((x, i) => (i !== 0 ? '&' : '?') + 'ids[]=' + x)
      .join('');
    const url = this.config.get().apiUrl + '/widgets' + params;

    return this.http.get(
      url,
      (response: WidgetHttpModel<BaseMetadataHttpModel, any>[]) => {
        const result: WidgetModel<any>[] = response.map((x) =>
          this.widgetsMapper.mapByType(x)
        );
        return result;
      }
    );
  }

  getWidgetById(
    id: string,
    shouldPoll?: boolean
  ): Observable<WidgetModel<any>> {
    const url = this.config.get().apiUrl + '/widgets/' + id;
    const request$ = this.http.get(
      url,
      (response: WidgetHttpModel<BaseMetadataHttpModel, any>) => {
        const result: WidgetModel<any> = this.widgetsMapper.mapByType(response);
        return result;
      }
    );
    return (shouldPoll
      ? request$.pipe(poll((req) => req.metadata.refreshInterval))
      : request$
    ).pipe(shareReplay(1));
  }
}
