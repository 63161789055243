import { Injectable } from '@angular/core';
import {
  CmsSectionHttpModel,
  CmsColHttpModel,
  CmsColModel,
  CmsPageModel,
  WidgetModel,
  CmsContentModel,
  CmsPageHttpModel,
  CmsHeaderHttpModel,
  CmsRowModel,
  CmsColPaddingHttpModel,
  CmsColPaddingModel,
  CmsShareInfoModel,
  CmsTextAlignmentType,
} from '@shared/models';
import { CmsHeaderModel } from '@shared/models/cms/cms-header.model';
import { CmsRowHttpModel } from '@shared/models/cms/http/cms-row.http-model';

@Injectable()
export class CmsMapperService {
  constructor() {}

  mapCmsHeader(response: CmsHeaderHttpModel): CmsHeaderModel {
    const result: CmsHeaderModel = {
      title: response.title,
      subtitle: response.subtitle,
      sliders: response.sliders
        .map((slider) => ({
          image: slider.image,
          order: slider.order,
          paragraph1: slider.paragraph1,
          paragraph2: slider.paragraph2,
          paragraph3: slider.paragraph3,
        }))
        .sort((x, y) => x.order - y.order),
    };
    return result;
  }

  mapCmsDataForPage(response: CmsPageHttpModel): CmsPageModel {
    const widgetIds = this.getWidgetIdsFromResponse(response.sections);
    const sectionUrls = response.sections.map((section) => section.section_url);
    const result: CmsPageModel = {
      widgetIds,
      sectionUrls,
      sections: response.sections.map((section) => ({
        sectionUrl: section.section_url,
        sectionName:
          section.section_name != null
            ? {
                text: section.section_name.text,
                text_en: section.section_name.text_en,
              }
            : null,
        showHeader: section.show_header,
        rows: section.rows.map((row) => this.mapRow(row)),
        order: section.order,
      })),
    };
    return result;
  }

  mapWidgetsDataToCmsData(
    cmsData: CmsPageModel,
    widgets: WidgetModel<any>[]
  ): CmsPageModel {
    if (cmsData == null) {
      return {
        sections: [],
        widgetIds: [],
        sectionUrls: [],
      };
    }

    const result: CmsPageModel = {
      ...cmsData,
      sections: cmsData.sections
        .map((section) => ({
          ...section,
          rows: section.rows.map((row) => this.addWidgetToRow(row, widgets)),
        }))
        .sort((x, y) => x.order - y.order),
    };
    return result;
  }

  private addWidgetToRow(
    row: CmsRowModel,
    widgets: WidgetModel<any>[]
  ): CmsRowModel {
    return {
      cols: row.cols.map((col) => this.addWidgetToCol(col, widgets)),
    };
  }

  private addWidgetToCol(
    col: CmsColModel,
    widgets: WidgetModel<any>[]
  ): CmsColModel {
    const result: CmsColModel = {
      ...col,
      content:
        col.content != null
          ? {
              ...col.content,
              ...this.addWidgetToContent(col.content, widgets),
            }
          : null,
      rows:
        col.rows != null
          ? col.rows.map((childRow) => this.addWidgetToRow(childRow, widgets))
          : null,
    };
    return result;
  }

  private addWidgetToContent(
    content: CmsContentModel,
    widgets: WidgetModel<any>[]
  ): CmsContentModel {
    let foundWidget = widgets.find(
      (widget) => widget.metadata.id === content.widget?.id
    );
    // use widget property from content to evaluate if bar chart data
    // should be trimmed (make separate columns for each value as
    // described in https://gitlab.com/operator-ict/golemio/code/pragozor/pragozor-frontend/-/issues/90)
    if (content.widget?.xAxisSingleTicks) {
      foundWidget = this.trimmedDataForBarChart(foundWidget);
    }
    return foundWidget != null
      ? {
          ...content,
          widgetData: foundWidget,
          widget: content.widget,
        }
      : content;
  }

  private trimmedDataForBarChart(
    widgetData: WidgetModel<any>
  ): WidgetModel<any> {
    return {
      metadata: widgetData.metadata,
      data: {
        xLabel: widgetData.data.xLabel,
        yLabel: widgetData.data.yLabel,
        series: widgetData.data.series[0].series.map((a) => {
          return {
            name: a.name,
            series: [
              {
                name: widgetData.data.xLabel,
                value: a.value,
              },
            ],
          };
        }),
      },
    };
  }

  getWidgetIdsFromResponse(response: CmsSectionHttpModel[]): string[] {
    const widgetIds = response
      .map((section) => section.rows)
      .reduce((a, b) => a.concat(b))
      .map((row) => this.getWidgetIdsFromRow(row))
      .reduce((a, b) => a.concat(b));
    return widgetIds;
  }

  private getWidgetIdsFromRow(row: CmsRowHttpModel): string[] {
    return row.cols
      .map((col) => this.getWidgetIdsFromCol(col))
      .reduce((a, b) => a.concat(b));
  }

  private getWidgetIdsFromCol(col: CmsColHttpModel): string[] {
    let widgetIds: string[] = [];
    if (col.content?.widget?.id != null) {
      widgetIds.push(col.content.widget.id);
    }
    if (col.rows != null) {
      const childWidgetIds = col.rows
        .map((childRow) => this.getWidgetIdsFromRow(childRow))
        .reduce((a, b) => a.concat(b));
      widgetIds = [...widgetIds, ...childWidgetIds];
    }
    return widgetIds;
  }

  getWidgetShareInfoFromRow(row: CmsRowModel): CmsShareInfoModel[] {
    return row.cols
      .map((col) => this.getWidgetShareInfoFromCol(col))
      .reduce((a, b) => a.concat(b));
  }

  getWidgetShareInfoFromCol(col: CmsColModel): CmsShareInfoModel[] {
    let shareInfos: CmsShareInfoModel[] = [];
    if (col.content?.widgetData != null) {
      shareInfos.push({
        source: col.content.widgetData.metadata.source,
        sourceUrl: col.content.widgetData.metadata.sourceUrl,
        updatedAt: col.content.widgetData.metadata.updatedAt,
        widgetId: col.content.widgetData.metadata.id,
        refreshInterval: col.content.widgetData.metadata.refreshInterval,
        isLiveData:
          col.content.widgetData.metadata.refreshInterval <=
          24 * 60 * 60 * 1000, // 24 hours
      });
    }
    if (col.rows != null) {
      const childShareInfos = col.rows
        .map((childRow) => this.getWidgetShareInfoFromRow(childRow))
        .reduce((a, b) => a.concat(b));
      shareInfos = [...shareInfos, ...childShareInfos];
    }
    return shareInfos;
  }

  replaceWidgetInCol(col: CmsColModel, widget: WidgetModel<any>): CmsColModel {
    if (col.content?.widgetData?.metadata.id === widget.metadata.id) {
      col.content.widgetData = widget;
      return col;
    }
    col.rows?.forEach((row) =>
      row.cols.forEach((childCol) => this.replaceWidgetInCol(childCol, widget))
    );
    return col;
  }

  private mapColPadding(
    padding: number | CmsColPaddingHttpModel
  ): number | CmsColPaddingModel {
    if (padding == null) {
      return {
        top: 0,
        bottom: 0,
        left: 15,
        right: 15,
      };
    }
    const paddingObj = padding as CmsColPaddingHttpModel;
    return (padding as any)?.hasOwnProperty('top')
      ? {
          bottom: paddingObj.bottom || 0,
          left: paddingObj.left || 15,
          right: paddingObj.right || 15,
          top: paddingObj.top || 0,
        }
      : padding;
  }

  private mapRow(row: CmsRowHttpModel): CmsRowModel {
    return {
      cols: row.cols.map((col) => this.mapCol(col)),
    };
  }

  private is_touch_device4() {
    if ('ontouchstart' in window || window.TouchEvent) {
      return true;
    }

    // if (window..DocumentTouch && document instanceof DocumentTouch) {
    //   return true;
    // }

    const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-'];
    const queries = prefixes.map((prefix) => `(${prefix}touch-enabled)`);

    return window.matchMedia(queries.join(',')).matches;
  }

  private mapCol(col: CmsColHttpModel): CmsColModel {
    const widgetIds = col.is_panel ? this.getWidgetIdsFromCol(col) : [];
    const result: CmsColModel = {
      type: col.type,
      isPanel: col.is_panel,
      padding: this.mapColPadding(col.padding),
      widgetIds,
      content:
        col.content != null
          ? {
              contentType: col.content.content_type,
              image: col.content.image,
              prahaPracujeWidget:
                col.content.prahaPracujeWidget != null
                  ? {
                      header: col.content.prahaPracujeWidget.header.toString(),
                      note: col.content.prahaPracujeWidget.note.toString(),
                    }
                  : null,
              text:
                col.content.text != null
                  ? {
                      type: col.content.text.type,
                      text: {
                        text: col.content.text.text.text,
                        text_en: col.content.text.text.text_en,
                      },
                      alignment: this.mapTextAlignment(
                        col.content.text.alignment
                      ),
                    }
                  : null,
              widgetData: null,
              crawlerData:
                col.content?.crawler_data != null
                  ? {
                      id_ref: col.content.crawler_data.id_ref,
                      header: {
                        text: col.content.crawler_data.header?.text,
                        text_en: col.content.crawler_data.header?.text_en,
                      },
                      paragraph: {
                        text: col.content.crawler_data.paragraph?.text,
                        text_en: col.content.crawler_data.paragraph?.text_en,
                      },
                    }
                  : null,
              widget:
                col.content?.widget != null
                  ? {
                      id: col.content.widget.id,
                      colorScheme: col.content.widget.color_scheme,
                      gradient: col.content.widget.gradient,
                      schemeType: col.content.widget.scheme_type,
                      showDataLabel:
                        col.content.widget.show_data_label ||
                        (this.is_touch_device4() &&
                          (col.content.widget.show_data_label_when_touch ===
                          true
                            ? true
                            : false)),
                      showXAxisLabel: col.content.widget.show_x_axis_label,
                      showYAxisLabel: col.content.widget.show_y_axis_label,
                      xAxisSingleTicks: col.content.widget.x_axis_single_ticks,
                      showLegend: col.content.widget.show_legend,
                      chartHeight: col.content.widget.chart_height,
                      showLabels: col.content.widget.labels,
                      marginBottom: col.content.widget.margin_bottom,
                      gaugeShowAxis: col.content.widget.gauge_show_axis,
                      legendMinHeight: col.content.widget.legend_min_height,
                      chartTitle: col.content.widget.chart_title,
                      hideTooltips:
                        col.content.widget.hide_tooltips ||
                        (this.is_touch_device4() &&
                          (col.content.widget.hide_tooltips_when_touch === true
                            ? true
                            : false)),
                      tooltipDisabled: col.content.widget.tooltip_disabled,
                      mapIconSize: col.content.widget.map_icon_size,
                      legendPosition: col.content.widget.legend_position,
                      roundEdges:
                        col.content.widget.round_edges != null
                          ? col.content.widget.round_edges
                          : true,
                      animations:
                        col.content.widget.animations != null
                          ? col.content.widget.animations
                          : true,
                    }
                  : null,
            }
          : null,
      rows: col.rows?.map((row) => this.mapRow(row)),
    };
    return result;
  }

  private mapTextAlignment(
    alignment: CmsTextAlignmentType | CmsTextAlignmentType[]
  ): CmsTextAlignmentType[] {
    if (alignment == null) {
      return [];
    }
    return Array.isArray(alignment) ? alignment : [alignment];
  }
}
