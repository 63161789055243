import { Injectable } from '@angular/core';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';

@Injectable()
export class ScrollService {
  private configFce = (section: string, offset = 0): ScrollToConfigOptions => ({
    target: section,
    offset: -30,
    duration: 1000,
  });

  constructor(private scrollToService: ScrollToService) {}

  scrollToSection(section: string, offset = 0) {
    this.scrollToService.scrollTo(this.configFce(section, offset));
  }

  scrollToSectionWithoutAnimation(section: string, offset = 0) {
    const config: ScrollToConfigOptions = {
      ...this.configFce(section, offset),
      duration: 0,
    };
    this.scrollToService.scrollTo(config);
  }

  scrollWithLoading() {}
}
