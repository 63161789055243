import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateModel } from '@shared/models/common';

@Injectable()
export class StateService {
  initialState: StateModel = {
    isContactVisible: false,
    isMissingSectionVisible: false,
    loadingVisible: false,
  };
  private stateSubject: BehaviorSubject<StateModel> = new BehaviorSubject(
    this.initialState
  );
  state$: Observable<StateModel> = this.stateSubject.asObservable();

  constructor() {}

  setContactVisibility(value: boolean) {
    const newState: StateModel = {
      ...this.initialState,
      isContactVisible: value,
    };
    this.stateSubject.next(newState);
  }

  setMissingSectionVisibility(value: boolean) {
    const newState: StateModel = {
      ...this.initialState,
      isMissingSectionVisible: value,
    };
    this.stateSubject.next(newState);
  }

  setLoadingVisibility(value: boolean) {
    const newState: StateModel = {
      ...this.initialState,
      loadingVisible: value,
    };
    this.stateSubject.next(newState);
  }
}
