import { Pipe, PipeTransform } from '@angular/core';
import { CmsSectionModel, CmsLocalizedTextModel } from '@shared/models';

@Pipe({
  name: 'prSectionsNames',
})
export class SectionsNamesPipe implements PipeTransform {
  constructor() {}

  transform(
    items: CmsSectionModel[]
  ): { sectionName: CmsLocalizedTextModel; url: string }[] {
    return items
      .filter((x) => x.sectionName != null)
      .map((x) => ({
        sectionName: x.sectionName,
        url: x.sectionUrl,
      }));
  }
}
