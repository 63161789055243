import {
  NgModule,
  APP_INITIALIZER,
  Optional,
  SkipSelf,
  ErrorHandler,
} from '@angular/core';

import * as services from './services';
import { ErrorHandlerService } from './services';
import { LocalizedTextPipe } from '@shared/pipes';

export function initConfig(
  config: services.CommonAppConfig
): () => Promise<{}> {
  return () => config.load();
}

const IMPORTS = [];

const SERVICES = [
  services.CommonHttpService,
  services.CommonAppConfig,
  services.WidgetsMapperService,
  services.CmsMapperService,
  services.CmsService,
  services.WidgetsService,
  services.ScrollService,
  services.StateService,
  services.CrawlerHelperService,
  LocalizedTextPipe,
  {
    provide: APP_INITIALIZER,
    useFactory: initConfig,
    deps: [services.CommonAppConfig],
    multi: true,
  },
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
  },
];

const EXPORTS = [];

@NgModule({
  imports: [...IMPORTS],
  declarations: [],
  providers: [...SERVICES],
  exports: [...EXPORTS],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
