import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { LottieModule } from 'ngx-lottie';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CoreModule } from './core/core.module';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ScullyLibModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory, useCache: true }),
    ScrollToModule.forRoot(),
    NgxHotjarModule.forRoot('3391003', 6),
    NgxHotjarRouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
