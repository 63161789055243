import { Injectable } from '@angular/core';
import {
  VisualNumberHttpModel,
  VisualNumberModel,
  TableHttpModel,
  BaseMetadataHttpModel,
  MetadataModel,
  ChartGaugeHttpModel,
  ChartBarHttpModel,
  ChartPieHttpModel,
  ChartLineHttpModel,
  WidgetModel,
  TableDataModel,
  ChartSeriesGroupModel,
  MapPraguePoiHttpModel,
  MapPraguePoiDataModel,
  MapPragueDistrictDataModel,
  MapPragueDistrictHttpModel,
  ChartDataModel,
  ChartNameValueModel,
  WidgetHttpModel,
  ChartGaugeDataModel,
  ChartPieDataModel,
  TableColumnModel,
} from '@shared/models/widgets';
import { LocalizedTextPipe } from '@shared/pipes';

@Injectable()
export class WidgetsMapperService {
  constructor(private localizedTextPipe: LocalizedTextPipe) { }

  mapByType(response: WidgetHttpModel<BaseMetadataHttpModel, any>) {
    switch (response.metadata.visual_type) {
      case 'chart-bar-horizontal':
        return this.mapChartBarHorizontal(response as ChartBarHttpModel);
      case 'chart-bar-vertical':
        return this.mapChartBarVertical(response as ChartBarHttpModel);
      case 'chart-bar-stack':
        return this.mapChartBarStacked(response as ChartBarHttpModel);
      case 'chart-donut':
      case 'chart-pie':
        const pie = this.mapChartPie(response as ChartPieHttpModel);
        return pie;
      case 'chart-line':
        return this.mapChartLine(response as ChartLineHttpModel);
      case 'gauge':
        return this.mapChartGauge(response as ChartGaugeHttpModel);
      case 'table':
        return this.mapTable(response as TableHttpModel);
      case 'visual-number':
        return this.mapVisualNumber(response as VisualNumberHttpModel);
      case 'map-prague-districts':
        return this.mapPragueDistricts(response as MapPragueDistrictHttpModel);
      case 'map-prague-pois':
        return this.mapPraguePois(response as MapPraguePoiHttpModel);
    }
  }

  mapChartBarVertical(
    response: ChartBarHttpModel
  ): WidgetModel<ChartDataModel> {
    return this.mapBarChart(response);
  }
  mapChartBarHorizontal(
    response: ChartBarHttpModel
  ): WidgetModel<ChartDataModel> {
    return this.mapBarChart(response);
  }
  mapChartBarStacked(response: ChartBarHttpModel): WidgetModel<ChartDataModel> {
    return this.mapBarChart(response);
  }
  mapChartLine(response: ChartLineHttpModel): WidgetModel<ChartDataModel> {
    const data = [];
    response.metadata.axis.y.series.forEach((axisY) => {
      const series: ChartNameValueModel[] = response.data
        .filter((x) => x[axisY.name] != null)
        .map((x) => {
          return {
            value: x[axisY.name],
            name: x[response.metadata.axis.x.name],
          };
        });
      const group: ChartSeriesGroupModel = {
        name: axisY.label,
        series,
      };
      data.push(group);
    });

    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        xLabel: response.metadata.axis.x.label,
        yLabel: response.metadata.axis.y.label,
        series: data,
      },
    };
  }
  mapChartPie(response: ChartPieHttpModel): WidgetModel<ChartPieDataModel> {
    const unit =
      response.data.length > 0
        ? this.localizedTextPipe.transform(response.data[0], 'unit')
        : null;
    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        data: response.data.map((x) => ({
          name: this.localizedTextPipe.transform(x, 'label'),
          value: x.value,
        })),
        unit,
      },
    };
  }

  mapChartGauge(
    response: ChartGaugeHttpModel
  ): WidgetModel<ChartGaugeDataModel> {
    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        data: [
          {
            name: this.localizedTextPipe.transform({
              text: response.data.label,
              text_en: response.data.label_en,
            }),
            value: response.data.value,
          },
        ],
        max: response.data.max,
        min: response.data.min,
        unit: {
          text: response.data.unit,
          text_en: response.data.unit_en,
        },
      },
    };
  }
  mapChartDonut(response: ChartPieHttpModel): WidgetModel<ChartPieDataModel> {
    const unit =
      response.data.length > 0
        ? this.localizedTextPipe.transform(response.data[0], 'unit')
        : null;
    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        data: response.data.map((x) => ({
          name: this.localizedTextPipe.transform(x, 'label'),
          value: x.value,
        })),
        unit,
      },
    };
  }
  mapTable(response: TableHttpModel): WidgetModel<TableDataModel> {
    const result = {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        headers: response.metadata.header_columns.map((x) =>
          this.localizedTextPipe.transform(x, 'label')
        ),
        table: response.data.map((row) =>
          response.metadata.header_columns.map((header) => {
            const localizedHeader = this.localizedTextPipe.transform(
              header,
              'name'
            );
            const cell: TableColumnModel = {
              value: row[localizedHeader],
              highlighted: row.highlighted || false,
            };
            return cell;
          })
        ),
      },
    };
    return result;
  }
  mapVisualNumber(response: VisualNumberHttpModel): VisualNumberModel {
    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        unit: response.data.unit,
        value: response.data.value,
        label: {
          text: response.data.label,
          text_en: response.data.label_en,
        },
      },
    };
  }
  mapPraguePois(
    response: MapPraguePoiHttpModel
  ): WidgetModel<MapPraguePoiDataModel[]> {
    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: response.data.map((x) => ({
        title: x.title,
        labelColor: x.label_color,
        lat: x.lat,
        lng: x.lng,
        lon: x.lon,
        description: x.description,
      })),
    };
  }

  mapPragueDistricts(
    response: MapPragueDistrictHttpModel
  ): WidgetModel<MapPragueDistrictDataModel[]> {
    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: response.data.map((x) => ({
        districtId: x.district_id,
        value: x.value,
        unit: {
          text: x.unit,
          text_en: x.unit_en,
        },
        title: x.title,
      })),
    };
  }

  private mapMetadata(
    id: string,
    metadata: BaseMetadataHttpModel
  ): MetadataModel {
    return {
      id,
      refreshInterval: metadata.refresh_interval,
      source: metadata.source,
      sourceUrl: metadata.source_url,
      unit: metadata.unit,
      unitEN: metadata.unit_en,
      type: metadata.visual_type,
      updatedAt: new Date(metadata.updated_at),
    };
  }
  private mapBarChart(
    response: ChartBarHttpModel
  ): WidgetModel<ChartDataModel> {
    const data = [];
    response.metadata.axis.y.series.forEach((axisY) => {
      const series: ChartNameValueModel[] = response.data
        .filter((x) => x[axisY.name] != null)
        .map((x) => ({
          value: x[axisY.name],
          name: x[response.metadata.axis.x.name],
        }));
      const group: ChartSeriesGroupModel = {
        name: axisY.label,
        series,
      };
      data.push(group);
    });

    return {
      metadata: this.mapMetadata(response.id, response.metadata),
      data: {
        xLabel: response.metadata.axis.x.label,
        yLabel: response.metadata.axis.y.label,
        series: data,
      },
    };
  }
}
